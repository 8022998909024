<template>
  <div>
    <b-row>
      <b-col
        sm="6"
        class="d-flex flex-column justify-content-center"
      >
        <b-card-text class="font-weight-bolder mb-0">
          {{ $t('members') }}
        </b-card-text>
      </b-col>
      <b-col sm="6">
        <div class="demo-inline-spacing float-right">
          <!-------------------------- Search box Start -------------------------->
          <b-form-group class="m-0">
            <b-form-input
              id="search_input"
              :placeholder="$t('search') + '...'"
              class="mb-1"
            />
          </b-form-group>
          <!-------------------------- Search box End ---------------------------->
          <!-------------------------- Add buttom Start -------------------------->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon m-0 mb-1 ml-1"
          >
            {{ $t('Add') }}
          </b-button>
          <!-------------------------- Add buttom End -------------------------->
        </div>
      </b-col>
    </b-row>
    <div class="overflow-hidden rounded">
      <b-table
        id="members-table"
        responsive
        striped
        :items="Object.keys(storeUsers)"
        :fields="membersTableInfo"
        table-class="custom-table"
      >
        <template #cell(name)="data">
          <b-media
            no-body
            style="width: 100px;"
          >
            <b-media-aside
              class="mr-1"
            >
              <b-avatar
                :src="storeUsers[data.item].photoURL"
              />
            </b-media-aside>
            <b-media-body class="my-auto text-left">
              <b-card-text class="font-small-3 mb-0 text-nowrap">
                {{ storeUsers[data.item].displayName }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </template>

        <template #cell(email)="data">
          <div class="">
            {{ storeUsers[data.item].email }}
          </div>
        </template>

        <template #cell(roles)="data">
          <b-badge
            v-if="storeUsers[data.item].roles[currentStore.sid] && storeUsers[data.item].roles[currentStore.sid].length > 0"
            pill
            variant="primary"
          >
            <span v-if="data.item === currentStore.uid">{{ $t('owner') }}</span>
            <span v-else>{{ storeUsers[data.item].roles[currentStore.sid][0] }}</span>
          </b-badge>
        </template>
        <template #cell(action)="data">
          <div class="d-flex justify-content-center">
            <b-dropdown
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              toggle-class="px-0 py-50 bg-transparent"
              variant="flat-dark"
              no-caret
              right
              @show="userSelectHandle({ roleName: storeUsers[data.item].roles[currentStore.sid], currentUser: storeUsers[data.item] })"
            >
              <template
                v-if="!storeUsers[data.item].roles[currentStore.sid] || (storeUsers[data.item].roles[currentStore.sid] && data.item === currentStore.uid)"
                v-slot:button-content
              >
                <feather-icon
                  :id="`box-pop-menu-${data.item.id}`"
                  icon="MoreVerticalIcon"
                  size="18"
                  class="cursor-pointer"
                />
              </template>
              <b-dropdown-item
                v-b-modal.role-update-modal
              >
                {{ $t('edit role') }}
              </b-dropdown-item>
              <b-dropdown-item
                @click="deleteUser({ user: storeUsers[data.item]})"
              >
                {{ $t('delete') }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
        <template #cell()="data">
          <div class="text-center">
            {{ data.value }}
          </div>
        </template>
        <template #head()="data">
          <div style="min-width: 30px;">
            {{ $t(data.label) }}
          </div>
        </template>
      </b-table>
    </div>
    <b-modal
      id="role-update-modal"
      :title="$t('update roles')"
      :hide-header-close="true"
      :no-close-on-esc="true"
      :ok-title="$t('update')"
      :cancel-title="$t('Cancel')"
      cancel-variant="outline-primary"
      footer-class="justify-content-end flex-row-reverse"
      centered
      @ok="updateRole({ roleName, user: currentUser })"
    >
      <b-form-group>
        <v-select
          v-model="roleName"
          label="title"
          :options="currentStore.roles.map(r => r.roleName)"
        />
      </b-form-group>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BButton, BCardText, BTable, BMediaAside, BAvatar, BMediaBody, BMedia, BDropdown, BDropdownItem, BModal, BBadge,
} from 'bootstrap-vue'
import store from '@/store'
import { mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BBadge,
    vSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BCardText,
    BTable,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BMedia,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentUser: null,
      roleName: null,
      membersTableInfo: [
        { key: 'name', label: 'name' },
        { key: 'email', label: 'email' },
        { key: 'roles', label: 'roles' },
        /* { key: 'certification', label: 'Certification' }, */
        { key: 'action', label: 'Action' },
      ],
      perPage: 8,
      currentPage: 1,
    }
  },
  computed: {
    ...mapGetters({
      storeUsers: 'getStoreUsers',
      currentStore: 'getCurrentStore',
    }),
    totalRows() {
      return this.storeUsers.length
    },
  },
  mounted() {
    store.dispatch('fetchStoreUsers')
  },
  methods: {
    userSelectHandle({ roleName, currentUser }) {
      this.roleName = roleName
      this.currentUser = currentUser
    },
    deleteUser({ user }) {
      store.dispatch('deleteUserFromStore', { sid: this.currentStore.sid, user })
    },
    updateRole({ roleName, user }) {
      store.dispatch('storeUpdateRole', { sid: this.currentStore.sid, roleName, user })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
